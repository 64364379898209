// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import { wrapText } from '../Form/Helpers'

type ImagePath = string;

type Props = {
  theme: {
    background: string,
    color: string,
    logo: ImagePath,
  }
}

const Footer = (props: Props) => {
    const { state } = React.useContext(Store)
    const { campaign } = state
    const { theme } = props

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        180
    )

    return (
        <div className={styles.container} style={{ background: theme.background, color: theme.color }}>
            <div className={styles.innerContainer}>
                <p className={styles.dealershipInfo}>
                    {state.campaign.client.name}

                    {
                        state.campaign.client.address &&
                            ` | ${Object.values(state.campaign.client.address).filter(_ => _).join(', ')}`
                    }

                    {
                        state.campaign.contactInfo.phoneNumber &&
                            ` | ${state.campaign.contactInfo.phoneNumber}`
                    }
                </p>

                <p className={styles.disclaimer}>{terms}</p>

                <img className={styles.logo} src={theme.logo} alt='' />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
