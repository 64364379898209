
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns14480329014207ef015c129f86183736en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '14480329014207ef015c129f86183736', flattenObject(ns14480329014207ef015c129f86183736en_US), true, true);
        
            import ns14480329014207ef015c129f86183736en from './en.yaml'
            i18n.addResourceBundle('en', '14480329014207ef015c129f86183736', flattenObject(ns14480329014207ef015c129f86183736en), true, true);
        
        export default '14480329014207ef015c129f86183736';
    